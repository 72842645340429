<template>
  <!-- <div > -->

  <div
    class="row position-relative"
    :class="
      RoutePage == 'QuizFinal' ? 'm-0 p-0 w-100 big-div-quiz' : ' w-85 big-div'
    "
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
    <script2 v-if="pageDesign.pageStyles.jsCode">
      {{ pageDesign.pageStyles.jsCode }}
    </script2>
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
      v-if="
        getImageAlign == 'no' &&
        getImageSrc(pageDesign.imageBlock, isDesktopView) !== ''
      "
      :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
      :style="
        getbackgroundImage(
          IsImageAdded,
          pageDesign.imageBlock,
          getImageAlign,
          isDesktopView
        ) + getBgDivStyles()
      "
    ></div>
    <!-- BackGroundDiv end-->
    <div
      class="px-0 px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      "
    >
      <div>
        <div
          v-for="(pro, ind) in pageDesign.blocksArray"
          :key="'preview' + ind"
          :class="[
            'item-' + ind,
            pro.type === 'tooltip' ? 'tooltip-zIndex' : '',
          ]"
          :data-aos="animationType"
          :data-aos-duration="getAnimationSpeedinMillis"
          :data-aos-delay="AOSAnimationDelay(ind)"
          :data-aos-anchor="`.item-${ind == 0 ? ind : ind - 1}`"
        >
          <div
            id="preview"
            :style="getHeightWidth(pro.style)"
            class="content"
            style="position: relative"
          >
            <div
              v-if="pro.type == 'text'"
              :style="
                getColorCss(pro.style) +
                getPaddingCss(pro.style) +
                getMarginCss(pro.style, isDesktopView) +
                getColorWithOpacity(pro.style) +
                getTextRotate(pro.style) +
                getLineHeight(pro.style) +
                getTextAlign(pro.style) +
                getFontStyle(pro.style, isDesktopView)
              "
              v-html="getTextValue(pro.content)"
            ></div>
            <div
              v-if="pro.type == 'tooltip'"
              :style="`display:flex;justify-content: ${pro.style.position} ; `"
            >
              <button
                :style="
                  getButtonCss(pro.style, isDesktopView) +
                  getCircularBtn(pro.isBtnRounded)
                "
                @mouseenter="pro.isTooltipVisible = true"
                @mouseleave="pro.isTooltipVisible = false"
              >
                <span v-if="pro.useIcon">
                  <BIcon :icon="pro.selectedIcon" />
                </span>

                <span
                  v-else
                  :style="{
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  }"
                >
                  {{ pro.text }}
                </span>
              </button>
              <ToolTipComp
                v-if="pro.isTooltipVisible"
                :tooltipData="pro"
                :isDesktopView="isDesktopView"
              />
            </div>
            <div
              v-if="pro.type == 'timer'"
              :style="`display:flex;justify-content: ${pro.style.position} ; flex-direction:column;  `"
            >
              <Timer
                :pro="pro"
                :isDesktopView="isDesktopView"
                :isEditor="false"
                @endCounter="endCounterAction"
              />
            </div>
            <div
              v-if="pro.type == 'button'"
              :style="`display:flex;justify-content: ${pro.style.position} ;  `"
            >
              <button
                :disabled="isLoadingQuestion"
                :style="getButtonCss(pro.style, isDesktopView)"
                @click="GoToNextPreviewPage"
                class="ButtonHover"
              >
                <b-spinner
                  class="mx-1"
                  v-if="isLoadingQuestion"
                  small
                  label="Small Spinner"
                ></b-spinner>
                <span
                  :style="{
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  }"
                >
                  {{ pro.text }}
                </span>
                {{ pro.style.alignItems }}
              </button>
            </div>

            <div v-if="pro.type == 'imageComp'">
              <InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
            </div>

            <div
              v-if="pro.type == 'html'"
              :style="
                getMarginCss(pro.style, isDesktopView) +
                getColorWithOpacity(pro.style) +
                getTextRotate(pro.style) +
                getTextAlign(pro.style)
              "
            >
              <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="px-0 px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageOpacity(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <!-- Show Logo Footer -->
    <div
      class="CreditLogo"
      style=""
      v-if="getPreviewSettings && getPreviewSettings.showLogoStatus"
    >
      <!-- -->
      <div class="text-end pr-3 pb-3">
        <a href="https://www.quizell.com?source=quiz" target="_blank" style="">
          <img
            src="https://images.quizell.com/website/creditMobileLogo.svg"
            alt="Quizell"
             style="height:35px;"
            class="CreditLogoImg d-block d-sm-none"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/0620/9998/0532/files/Group_69111_2.svg?v=1660804067"
            alt="Quizell"
             style="height:35px;"
            class="d-none d-sm-block"
          />
        </a>
      </div>
    </div>
  </div>

  <!-- </div> -->
</template>

<script>
import Vue from "vue";
import VS2 from "vue-script2";
Vue.use(VS2);
import { mapGetters, mapActions, mapMutations } from "vuex";
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
import Axios from "axios";
export default {
  mixins: [cssFunctionMixin],
  props: [
    "isDesktopView",
    "apiToken",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "isTest",
  ],
  components: {
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),
    Timer: () =>
      import(/* webpackChunkName: "Timer" */ "./components/Timer.vue"),
    ToolTipComp: () =>
      import(/* webpackChunkName: "ToolTipComp" */ "./ToolTipComp.vue"),
    InlineImageComp: () =>
      import(
        /* webpackChunkName: "InlineImageComp" */ "./components/InlineImageComp.vue"
      ),
  },
  data() {
    return {
      activeSideBar: "pageStyle",
      pageDesign: {},
      delayIncrement: 0.2, // The delay between each item,
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
    };
  },
  computed: {
    isLoadingQuestion() {
      return this.getIsLoadingQuestion;
    },
    getImageAlign() {
      if (
        this.pageDesign.pageStyles.useMobileImageAlign &&
        !this.isDesktopView
      ) {
        return this.pageDesign.pageStyles.mobileImageAlign;
      } else {
        return this.pageDesign.pageStyles.imageAlign;
      }
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.src !== "" &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" || this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },
    firstTextIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "text");
    },
    firstButtonIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "button");
    },
    ...mapGetters([
      " getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getPreviewSettings",
      "getAnimationSetting",
      "getSavedLeadID",
      "getTotalPreviewPages",
      "getEnvConst"
    ]),
    CurrentSelectedPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" ||
        this.getUploadedImage
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no" ? true : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
    QuestionTitleExist() {
      const tempArray = [];
      this.pageDesign.blocksArray.forEach((x) => {
        if (x.type == "text") {
          tempArray.push(x);
        }
      });
      const questionTitleExist = (element) => element.questionTitle === true;
      return tempArray.some(questionTitleExist);
    },
    AOSAnimationDuration() {
      return this.getIsAnimationEnabled ? this.getAnimationSpeedinMillis : 50;
    },
     kozimastoreAndCompareDateTime() {
    const now = new Date();
    const currentDateTimeISO = now.toISOString().split('T')[0];
    if(!localStorage.getItem('kozimaDateTime'))
    {
      localStorage.setItem('kozimaDateTime',currentDateTimeISO)
      return true
    }
    
    if (localStorage.getItem('kozimaDateTime') >= currentDateTimeISO) {
        return false
    } else {
      localStorage.removeItem('kozimaDateTime')
      localStorage.setItem('kozimaDateTime',currentDateTimeISO)
     return true
    }
  },
  },
  created() {
    this.pageDesign = this.getStartedPageValues();
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },

    ...mapMutations([
      "setQuizStarted",
      "nextPageAnimation",
      "backPageAnimation",
      "resetKozimaDetail",
      "jumpToPreviewPage"
    ]),
    ...mapActions(["goToNextPreviewPage", "goToBackPreviewPage",'handleKozmicGetStartedTimerReset']),
    GoToNextPreviewPage() {
      this.nextPageAnimation();
      this.goToNextPreviewPage(this.getSelectedPage.value);
    },
    endCounterAction(e) {
      if (e.action == "newTab") window.open(e.url, "_blank");
      else if (e.action == "currentTab") window.open(e.url, "_self");
      else if (e.action == "nextPage") this.GoToNextPreviewPage();
    },
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " +
        this.getQuizPageHeight(
          this.pageDesign.pageStyles.height,
          this.RoutePage,
          this.isTest
        );

      return design;
    },
    getStartedPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },

    covertQuestionTitle() {
      this.pageDesign.blocksArray.forEach((x) => {
        if (x.type == "text") {
          x.questionTitle = true;
        }
      });
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
   async getKozimaIPDetail(){
      try{
        const response = await Axios.get('/api/quiz/check-submission',{params:{api_token:this.apiToken}});
       if(!response.data.data.is_allowed)
       {
         const resultPageIndex = this.getTotalPreviewPages.length-1;
     this.jumpToPreviewPage(resultPageIndex)
       }
      }
      catch(e){
        console.log(e)
        throw e
      }
    }
  },
  watch: {
    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
      this.pageDesign = this.getStartedPageValues();
    },
  },
 
 async mounted() {
  
   const kozimaDetail= this.getEnvConst?.KOZIMA_GAME?JSON.parse(this.getEnvConst?.KOZIMA_GAME)[0]:{}
   if(this.getPreviewSettings.quizId == kozimaDetail?.quizId)
  {
   
   this.handleKozmicGetStartedTimerReset(this.getSelectedPage.id)

  }
 

   this.UpdateDynamicText();
 }
};
</script>

<style>
.arrow1::before {
  content: "\2023";
  font-size: 58px;
  position: absolute;
  top: -47px;
  right: 0px;
  transform: rotate(26deg);
  color: #ffffff;
}

.slideRTL {
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.CreditLogo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.CreditLogoImg {
  width: 150px;
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .CreditLogo {
    top: 10px;
    right: 5px;
    height: 50px;
  }
}
.tooltip-zIndex {
  position: relative;
  z-index: 11111;
}
</style>
